img{
	max-width: 100%;
}
.gallery{
	background-color: #dbddf1;
	padding: 0 10% 0 10%;
}
.gallery img{
	background-color: #ffffff;
	padding: 15px;
	width: 100%;
	box-shadow: 0 0 15px rgba(0,0,0,0.3);
	cursor: pointer;
}
#gallery-modal .modal-img{
	width: 100%;
}

.content1{
    text-align: center;
    font-size: 30Px;
    font-weight: bold;
    color: #af8554;
    padding-bottom: 2%;
    padding-top:2%;

}

.videosection{
	background-color: #dbddf1;
	padding: 0 10% 0 10%;
    

}

.videosection iframe{
	background-color: #ffffff;
	padding: 15px;
	width: 100%;
	box-shadow: 0 0 15px rgba(0,0,0,0.3);
	cursor: pointer;
}

.eventrowarea{
    background-color: #AF8554;
    color:white;
    width: 110vw;
    font-size: 30px;
   
}