#Card_Text{
    position:absolute;
    bottom: -230px;
    transition: 0.2s;
}


#card_Hover{
    cursor: pointer;
}

#card_Hover:hover #Card_Text{
    bottom: 0px;
}

img{
    transition: 0.2s;
}

#card_Hover:hover img{
    transform: scale(1.1);
}