*{
    margin: 0;
    padding: 0;

}
body{
    margin:0;
    background: #dbddf1;;
}

.bg{
    width: 95%;
    min-height:100vh ;
    margin:0 auto;
    position:relative;
}

.bg h1{
    color: #fff;
    text-align: center;
    padding: 15px;
    font-size: 40px;
}

.bg .media-container{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 15px;
    margin: 20px auto;
}

.bg .media-container .media{
    height: 250px;
    width:350px;
    border: 10px solid #fff;
    box-shadow: 0 5px 15px rgba(0,0,0,0.7);
    overflow: hidden;
    cursor: pointer;
}

.bg .media-container .media :is(video, img){
    height: 100%;
    width:100%;
    object-fit: cover;
    transition: all 0.3s linear;
}



.bg .media-container .media:hover :is(video, img){
transform: scale(1.1);
}


.bg .popup-media{
    position: fixed;
    top:0;
    left:0;
    z-index:100;
    background: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
}

.bg .popup-media video{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    display: block;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border: 3px solid #fff;
}

.bg .popup-media img{

    position: absolute;
    top:50%; left:50%;
      transform: translate(-50%, -50%);
      border: 5px solid #fff;
      border-radius:5px ;
      width: 750px;
      object-fit:cover;
}



.bg .popup-media span{
    position: absolute;
    top:5px;
    right:20px;
    font-size: 50px;
    font-weight: bolder;
    z-index: 100;
    cursor: pointer;
    color: #fff;
    user-select: none;
}