*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.star{
    color:#af8454;
}

.d-flex-header{
    color:#af8454; 
    font-weight: bold;
    font-size: 15px;
}

.icons{
    width: 7%;
    height: 10%;
    padding-right: 2%;
    padding-top: 1%;
}
.icons1{
    width: 13%;
    height: 10%;
    padding-top: 2%;
    padding-right: 2%;
}
.value{
 color:#666666;
 font-weight: normal;

}
.contact-btn{
    background-color: #af8454;
    color: white;
    border-color: #8d6232;
    font-size: 15px;
    width: 20%;
    height: 10%;
}
.col-md-7{
    /* padding: 20px; */
    padding: 5px 40px 10px 40px;
}
.col-md-5{
  
    /* padding: 20px; */
    padding: 5px 10px 10px 50px;
 


}
.contact-btn:hover{
    background-color: transparent;
    color: #af8454;
    border-color:  #8d6232;
    border:3px solid ;
}

.contact-headers{
    font-size: 25px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.form-control {
    height: 52px;
    background: #fff;
    color: #000;
    font-size: 14px;
    border-radius: 2px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.form-label{
    font-size: 15px;
}

.map{
    padding: 5px 40px 0 40px;
}
